
/* Estilos de barra de navegacion */
.navwrapper {
  padding-top: 3.5rem;
  padding-right: 1rem;
  padding-bottom: 0.7rem;
  padding-left: 1rem;
  /*box-shadow: 0 9px 9px 0 rgba(47, 44, 44, 0.2);*/
  background-color: rgb(41, 128, 185);
  height: 120px;
}

.nav{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center !important;
}

.navlist{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 90%;
}

.navlist li{
  flex-grow: 1;
  font-size: 1.3rem;
  margin-bottom: 10px;
  padding-bottom: 9px;
}

.navlist li a{ 
  color: #FFF;
  text-decoration: none;
  line-height: 1.5;
  cursor: pointer;
  font-weight: 500;
}

.effect-bottom-navbar-link::after{
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #FFF;
    transition: width .3s;
    margin: 0 auto;
}

.effect-bottom-navbar-link:hover::after{
  width: 70%;
}






/* Fin de estilos de barra de navegacion */


/* Estilos de Cards de Menu */
.container-menu-options{
  width: 100%;
  height: calc(100% - 120px);
  position: absolute;
  background-image: url("./Resourses/main-web.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.autoMargin-option{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

.margin-top-row{
  width: 100%;
  padding: 10px 5%;
}




/* Fin de estilos de cards */



/* Estilos de Login */

.loginwrapper{
  text-align: center;
  color: #fff;
  background: #222;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;
}

.container-login-form{
  text-align: center;
  width: 50% !important;
}

.container-login-form > h2{
  margin: 35px auto;
}

.container-login-form .form button{
  border-radius: 30px;
  width: 50%;
  margin-top: 15px;
  font-weight: bold;  
} 


.input-login{
  /*box-shadow: 0 0 13px rgba(30, 126, 52, 0.9);*/
}


/* Fin de estilos */



/* Cards de Menu */
.card.card-body{
  /*background: linear-gradient(-45deg, #EF6F6C 50%, rgba(255, 255, 255, 0.5) 50%);*/
  background-color: rgb(41, 128, 185 , 1);
  color: #FFF;
  cursor:  pointer;
}

.card-body:hover {
  box-shadow: 1px 5px 15px rgba(0, 0, 0, 0);
  background: linear-gradient(to bottom, #208FF9 90%, #00bdea 90%);
}

.card-body button{
  background-color: transparent;
  border-color: #FFF;
  border-radius: 20px;
  display: none;
}

.card-body button:hover {
  color: rgb(39, 106, 133);
  background-color: #FFF;
  border-color: #FFF;
}

.no-text-decoration:hover{
  text-decoration: none;
}
/* Fin de estilos */


/* form de add caso */

.container-addcaso-form{
  width: 100%;
  height: calc(100% - 120px);
  overflow-y: scroll;
  position: absolute;
  background-image: url("./Resourses/main-web.png");
}

.form-add-caso{
  padding: 50px 5px;
  height: auto;
  margin: 30px auto;
  width: 65%;
  background: linear-gradient(to bottom, rgba(89, 91, 101) 20%, #FFF 20%);
  /*box-shadow: 0 0 9px 3px rgba(0, 0, 0, 0.2);*/
  border-radius: 8px;
}

.form-group{
  margin: 20px 10%;
}

.container-editcaso-form .form-group{
  margin: 20px -15px !important;
}

.sidebar-4 .form-group {
    margin: 20px -15px !important;
}

.logo-div-caso-form{
  margin-bottom: 50px;
}

.title-add-caso-form{
  color: #FFF;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  font-family: sans-serif;
}

.button_addcaso{
  height: 40px;
  border: none;
  width: 150px;
  font-weight: 550;
}

.button_addcaso:hover{
    background-color: #222;
    color: #FFF;
}

/* Fin de form de add caso */



/* Form de Editar caso */

.container-editcaso-form{
  width: 100%;
  height: calc(100% - 120px); 
  overflow-y: scroll;
  position: absolute;
  background-image: url("./Resourses/main-web.png");
  padding: 2.5% 40px 30px !important;
  display: grid;
  grid-template-columns: 50% 50%;
}

@media ( max-width: 1000px ){
  
  .container-editcaso-form{
    width: 100%;
    height: auto;
    position: absolute;
    background-image: url("./Resourses/main-web.png");
    padding: 2.5% 40px;
    display: grid;
    grid-template-columns: 95%;
  }

  .columna-de-form-edit-caso{
    margin-bottom: 40px !important;
  }

}

.container-editcaso-form label, 
.container-editcaso-form legend
{
  text-align: end;
  color: #FFF;
}

.columna-de-form-edit-caso{
  padding: 10px 25px;
  border: dashed 1px #FFF;
  border-radius: 10px;
  margin: 0 15px;
}

.anadir_evento.row.form-group {
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.anadir_evento.row.form-group label{
  text-align: center;
  margin-top: 10px;
}

.anadir_evento.row.form-group hr{
  color: #FFF;
  background: #FFF;
  height: 1px;
  width: 95%;
}

.anadir_evento.row.form-group button{
  margin: 20px 10px;
}

.anadir_evento_caso_label{
  text-align: end !important;
}

.tabla_de_eventos_a_anadir .table-responsive{
  height: 200px;
}

.button_edit_case{
  height: 40px;
  border: none;
  width: 150px;
  font-weight: 550;
  margin-top: 30px;
}

.tabla_de_eventos_ya_anadidos .table-responsive{
  max-height: 364px;
  color: #FFF;
}

.tabla_de_eventos_ya_anadidos .table{
  color: #FFF !important;
}

.tabla_de_eventos_a_anadir .table{
  color: #FFF !important;
}

.tabla_de_eventos_ya_anadidos.row.form-group {
    margin-top: 34px !important;
}

.tabla_eventos_resumen{
  font-size: 14px;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
}

.anadir_evento.row.form-group hr{
  transform: translateY(-5px);
}

.anadir_evento.row.form-group label{
  transform: translateY(-10px);
}

.container-editcaso-form .columna-de-form-edit-caso .form-group .game_buttons{
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.box-description-case{
  border: none;
  width: 100%;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 8px;
  margin: 10px 0;
  min-height: 50px;
  overflow-y: scroll;
}

.docs-resume-case{
  width: 100%;
  height: auto;
  margin: 30px 0px 30px;
  border: none;
  padding: 5px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  grid-row-gap: 20px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
}

.form-add-docs{
  border: none;
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.15);
}

.order-adddocs-box{
  display: flex;
  flex-direction: column;
  text-align: left;
}
/* Fin de form de edit caso */

/* Estilos de Pantalla de Clientes */
.container-clientes-options{
  width: 100%;
  height: calc(100% - 120px);
  position: absolute;
  background-image: url("./Resourses/main-web.png");
}

.contenedor-de-cajas-clientes-options{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.sidebar-4-clientes-options,
.panel-8-clientes-options{
  height: 100%;
  cursor: pointer;
  overflow-y: scroll;
}

.sidebar-4-clientes-options{
  background-color: #35495d;
}

.panel-8-clientes-options{
  background-color: #FFF;
}

.sidebar-4-header-clientes-options{
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-items: center;
  padding: 20px auto;
}


.sidebar-4-header-clientes-options h3{
  color: #FFF;
  font-family: 'Crimson Text', serif;
  font-size: 35px;
  font-weight: bold;
}

.sidebar-4-header-clientes-options hr {
  background-color: #FFF;
  width: 85%;
  height: 1px;
}

.sidebar-4-links-option-clientes-options li a {
  font-family: 'Crimson Text', serif;
  font-size: 20px;
}

.sidebar-4-links-option-clientes-options li.active {
  background-color: rgba(0,0,0,.2);
}

.panel-all-clientes-view{
  height: 90%;
  max-height: 90%;
}

.form-panel-all-clientes-view{
  margin-bottom: 60px;
}

.input-search-div-container:hover input{
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.6);
}

.panel-just-selected-clientes-view{
  height: 90%;
  max-height: 90%;
}

.containerCardsInfo{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cards-info-client{
  margin: 5px 10px;
  background-color: transparent !important;
  color: #000 !important;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
  width: 50%;
}

.containerCardsInfo .card-body:hover {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.6);
  background: transparent;
  transform: scale(1.03, 1.03);
}

.statistics-card-client{
  display: flex;
  width: 100%;
}

.statistics-card-client h3{
  width: 50%;
  font-size: 20px;
}

.statistics-card-client h3.firts{
  text-align: right;
  margin-right: 20px;
}

.cards-info-client-address{
  width: 100%;
  margin: 5px auto;
  background-color: transparent !important;
  color: #000 !important;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
  height: auto;
}


.panel-insert-client-view{
    height: 90%;
    max-height: 90%;
    overflow-y: scroll;
}

.form-add-client{
    padding: 50px 5px;
    height: auto;
    margin: 30px auto;
    width: 98%;
    background: linear-gradient(to bottom, rgba(89, 91, 101) 20%, #FFF 20%);
    border-radius: 8px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
}

.logo-div-add-cliente-form{
  margin-bottom: 50px;
}

.title-add-cliente-form{
  color: #FFF;
  font-size: 25px;
  font-weight: 600;
  font-style: normal;
  font-family: sans-serif;
}

.button_addcliente{
  font-weight: 650 !important;
  margin-top: 15px;
}

.container-resume-caso{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  max-height: 85%;
  overflow-y: scroll;
  padding: 10px 20px;
}

.form-relate-case-user{
  padding: 50px 5px;
  height: auto;
  max-height: 900px;
  margin: 30px auto 5px;
  width: 98%;
  background: linear-gradient(to bottom, rgb(89, 91, 101) 160px, #FFF 160px);
  border-radius: 8px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
}

.panel-associate-user-case-client-view{
  height: 90%;
  max-height: 90%;
}




/* Vista de Agendas */

.container-first{
  width: 100%;
  height: calc(100% - 120px);
  position: absolute;
}

.container-panel-2-segments{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.sidebar-4,
.panel-8{
  height: 100%;
  cursor: pointer;
  overflow-y: scroll;
}

.sidebar-4{
  background-color: #35495d;
}

.panel-8{
  background-color: #FFF;
}

.sidebar-4-header{
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-items: center;
  padding: 20px auto;
}


.sidebar-4-header h3{
  color: #FFF;
  font-family: 'Crimson Text', serif;
  font-size: 35px;
  font-weight: bold;
}

.sidebar-4-header hr {
  background-color: #FFF;
  width: 85%;
  height: 1px;
}

.sidebar-4 .row.form-group label{
  color: #FFF;
}

.panel-8 .title-div-panel h1.title-panel{
  color: rgba(89, 91, 101);
  font-family: Crimson Text;  
  font-weight: 600;
  margin: 20px auto;
}

.form-relate-changes {
    padding: 50px 5px;
    height: 570px;
    margin: 30px auto 5px;
    width: 98%;
    background: linear-gradient(to bottom, rgb(89, 91, 101) 30%, #FFF 30%);
    border-radius: 8px;
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
}


.render-in-panel{
  margin: 10px;
  padding: 20px;
  height: 70vh;
  overflow-y: scroll;
  overflow-x: scroll;
  max-height: 70vh;
}

.item-events{
  width: 100%;
  margin-bottom: 40px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  color: #000 !important;
}

.group-buttons{
  width: 90%;
  margin-bottom: 20px;
}

.chart-container{
  width: 90%;
  height: 250px;
  margin: 40px auto;
}

.auto-height-panel{
  height: auto !important;
}

.control-panel-chart{
  overflow-y: scroll;
}

.dashboard-cards-container{
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}



/* Administrador panel */
.admin-title-style{
  font-size: 30px !important;
}

.form-panel-user-edit-info{
  background: linear-gradient(to bottom, rgba(89, 91, 101) 40%, #FFF 40%) !important;
}

.options-user{
  background-color: #FFF !important;
  color: #000 !important;
  border-left: solid 5px  #2471a3 !important;
}

.options-user-icon{
  position: absolute;
  right: 20px; 
  top: 15px;
}

.form-base{
  padding: 50px 5px;
  height: auto;
  margin: 30px auto;
  width: 98%;
  background: linear-gradient(to bottom, rgba(89, 91, 101) 30%, #FFF 30%);
  border-radius: 8px;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.4);
}

.form-base .title-form {
  color: #FFF;
  font-size: 25px;
  font-weight: 700;
}

.adminpanel.tabla-activity-panel{
  height: 350px;
  max-height: 350px;
  overflow-y: scroll;
}

.container-sticky-notes{
  width: 95%;
  height: 330px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 10px;
  grid-gap: 20px;
}

.sticky-note{
  width: 100%;
  padding: 10px;
  background-color: #FAAACA;
  height: 180px;
  box-shadow: 5px 5px 10px -2px rgba(33, 33, 33, 0.3);
  transform: skew(-1deg, 1deg);
  transition: transform .15s, -webkit-transform .15s;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.sticky-note div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.sticky-note div.title{
  height: 25%;
}

.sticky-note div.title h3{
  font-family: 'Crimson Text', cursive;
  font-size: 20px;
  font-weight: 600;
}

.sticky-note div.contenido{
  height: 75%;
  overflow-y: scroll;
}

.sticky-note div.contenido p{
  font-family: 'Crimson Text', cursive;
  font-size: 18px;
  font-weight: 425;
}


.sticky-note div input,
.sticky-note div textarea{
  width: 100% !important;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100% !important;
  padding: 0 !important;
  font-family: 'Crimson Text', cursive;
  font-size: 20px;
  font-weight: 425;
}

.boton-accion-sticky-ok{
  position: absolute;
  right: -15px;
  width: 25px;
  height: 25px;
  border-radius: 75px;
  overflow: hidden;
  background-color:  #1abc9c;
  top: -10px;
}

.boton-accion-sticky-delete{
  position: absolute;
  right: -15px;
  width: 25px;
  height: 25px;
  border-radius: 75px;
  overflow: hidden;
  background-color:  #e74c3c;
  top: -10px;
}


@media (max-width: 500px){
  
  .container-login-form > h2 {
    font-size: 1.8rem;
  }

}


@media (max-width: 800px){

  .container-login-form {
    text-align: center;
    width: 90% !important;
  }

  .container-login-form .form button {
    width: 62% !important;
  }

}


@media (min-width: 801px) and (max-width: 1023px){

  .container-login-form {
    text-align: center;
    width: 75% !important;
  }

}

/* control de elemntos de overflow scroll en navegadores */
.sidebar-4-clientes-options::-webkit-scrollbar,
.panel-8-clientes-options::-webkit-scrollbar{
  display: none;
}

.sidebar-4::-webkit-scrollbar,
.panel-8::-webkit-scrollbar{
  display: none;
}

.render-in-panel::-webkit-scrollbar{
  display: none;
}

.container-resume-caso::-webkit-scrollbar{
  display: none;
}

.panel-insert-client-view::-webkit-scrollbar{
  display: none;
}

.container-sticky-notes::-webkit-scrollbar{
  display: none;
}

.table-panel-all-clientes-view::-webkit-scrollbar {
    display: none;
}

.container-editcaso-form::-webkit-scrollbar {
    display: none;
}

.container-addcaso-form::-webkit-scrollbar {
    display: none;
}

.ellipsisStyle{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Logout component */
.float-out-component{
  position: absolute;
  width: 50px;
  height: 50px;
  top: 40px;
  right: 5px;
  border-radius: 75px;
  background-color: aliceblue;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
}

.float-addfase-component{
  position: absolute;
  width: 100px;
  height: 35px;
  bottom: 15px;
  right: 15px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  background-color: #138496;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  text-align: center;
}

.float-addaction-component{
  position: absolute;
  width: 100px;
  height: 35px;
  bottom: 15px;
  right: 115px;
 /* border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;*/
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  background-color: #138496;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  text-align: center;
}

.modal-body .form-inline.ajustar-forms-modales .form-group{
  width: 100%;
  margin: 10px 0;
}

.modal-body .form-inline.ajustar-forms-modales .form-group label{
  width: 35%;
}

.modal-body .form-inline.ajustar-forms-modales .form-group input{
  width: 65%;
}

.float-additem-component{
  position: absolute;
  width: 100px;
  height: 35px;
  bottom: 15px;
  right: 215px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  background-color: #138496;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  text-align: center;
}

.resume-caso-ctrl-view{
  width: 100%; 
  height: 50px; 
  border: solid 0.6px rgb(41, 128, 185); 
  border-radius: 8px;
  border-left: solid 5px rgb(41, 128, 185);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.caso-ctrl-view-element::before{
  width: 15px;
  height: 15px;
  position: absolute;
  left: 8px;
  border: solid 4px #ddd;
  background: #ffb300;
  /*content: '';*/
  border-radius: 25px;
}

.resume-caso-ctrl-view h5{
  margin: 0 auto;
}

.resume-caso-ctrl-view span{
 font-weight: bold;
 font-size: 28px;
 margin-right: 10px;
}

.modalUserCaseAdd {
  width: 75vw !important;
  max-width: 75vw !important;
}

.modalUserCaseAdd .title-div-cliente-form,
.modalUserCaseAdd .logo-div-add-cliente-form,
.centrarBtnAsociatte{
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
}